<template>
    <div style="padding-top: 10%">
        <v-col xs12>
            <v-row align="center" justify="center" clas="fill-height">
                <div>
                    <div class="text-xs-center">
                        <v-card flat>
                            <v-progress-circular  :size="100" :width="7" color="primary"  indeterminate></v-progress-circular>
                        </v-card>                   
                    </div> 
                </div>
            </v-row>
        </v-col>
    </div>
</template>

<script>
import api from '@/api/autenticacion'

export default {
  mounted () {
    sessionStorage.clear()
    api.logout()
    this.$store.dispatch('logout')
    // this.$router.push('/login')
    location.reload()
  }
}
</script>

<style>

</style>
